var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c("b-table", {
        key: "sent_" + _vm.redrawIndex,
        staticClass: "mt-2",
        attrs: {
          small: "",
          bordered: "",
          items: _vm.items,
          hover: "",
          fields: _vm.fields,
          busy: _vm.loading,
        },
        on: { "row-clicked": _vm.clickedRow },
        scopedSlots: _vm._u([
          {
            key: "cell(subject)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-break" }, [
                  _vm._v(_vm._s(data.item.subject)),
                ]),
              ]
            },
          },
          {
            key: "cell(roomId)",
            fn: function (data) {
              return [_vm._v(_vm._s(data.item.roomId))]
            },
          },
          {
            key: "cell(startTime)",
            fn: function (data) {
              return [_vm._v(_vm._s(_vm.FormatDateTime(data.item.startTime)))]
            },
          },
          {
            key: "cell(endTime)",
            fn: function (data) {
              return [_vm._v(_vm._s(_vm.FormatDateTime(data.item.endTime)))]
            },
          },
          {
            key: "cell(location)",
            fn: function (data) {
              return [
                _c("img", {
                  staticClass: "icon mr-2",
                  attrs: {
                    src: _vm.messageIcon(data.item.location),
                    alt: _vm.$t("MESSAGES.TYPE." + data.item.location),
                  },
                }),
              ]
            },
          },
          {
            key: "cell(id)",
            fn: function (data) {
              return [
                _c("img", {
                  staticClass: "icon mr-2",
                  attrs: {
                    src: _vm.messageIcon(data.item.location),
                    alt: _vm.$t("MESSAGES.TYPE." + data.item.location),
                  },
                }),
                _vm._v("\n      " + _vm._s(data.item.subject) + "\n      "),
                _c("div", { staticClass: "time-information f12" }, [
                  _vm._v(
                    _vm._s(_vm.FormatDateTime(data.item.startTime)) +
                      " - " +
                      _vm._s(_vm.FormatDateTime(data.item.endTime))
                  ),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("MESSAGES.TYPE." + data.item.location))),
                ]),
              ]
            },
          },
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [_c("b-spinner", { staticClass: "align-middle" })],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-pagination", {
        staticClass: "mt-2",
        attrs: {
          "total-rows": _vm.totalItems,
          "per-page": _vm.limit,
          align: "center",
          pills: "",
        },
        on: { input: _vm.getDrafts },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }