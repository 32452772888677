<template>
    <section class="mt-2">
      <b-table 
        small
        bordered
        :items="items"
        hover
        class="mt-2"
        :key="'sent_' + redrawIndex"
        :fields="fields"
        :busy="loading"
        @row-clicked="clickedRow"
      >
        <template v-slot:cell(subject)="data">
          <div class="text-break">{{ data.item.subject }}</div>
        </template>
        <template v-slot:cell(roomId)="data">{{ data.item.roomId }}</template>
        <template v-slot:cell(startTime)="data">{{ FormatDateTime(data.item.startTime) }}</template>
        <template v-slot:cell(endTime)="data">{{ FormatDateTime(data.item.endTime) }}</template>
        

        <template v-slot:cell(location)="data">
          <img class="icon mr-2" :src="messageIcon(data.item.location)" :alt="$t('MESSAGES.TYPE.' + data.item.location)"  />
        </template>

        <template v-slot:cell(id)="data">
          <img class="icon mr-2" :src="messageIcon(data.item.location)" :alt="$t('MESSAGES.TYPE.' + data.item.location)"  />
          {{ data.item.subject }}
          <div class="time-information f12">{{ FormatDateTime(data.item.startTime) }} - {{ FormatDateTime(data.item.endTime) }}</div>
          <p>{{ $t('MESSAGES.TYPE.' + data.item.location) }}</p>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
  
      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        align="center"
        @input="getDrafts"
        pills
      >
      </b-pagination>
    </section>
  </template>
  
  <script>
  export default {
    props: [
      "searchText",
      "limit",
      "functionbox_uuid",
      "user_uuid",
      "refresh"
    ],
    data() {
      return {
        loading: false,
        currentPage: 1,
        redrawIndex: 0,
        items: [],
        selectedIndex: 0,
        totalItems: 0,
      };
    },
    methods: {
      rowClass(item, type) {
        if (item && type === "row") {
          let returnClass = "";
          if (item.archived != 0) {
            returnClass = "archived";
          }
          if (item.fetched === 0) {
            returnClass += " not-viewed";
          }
          return returnClass;
        }
        return "";
      },
      clickedRow(data, index) {
        this.selectedIndex = index;
        this.$emit("draftMeeting", data);
      },
      messageIcon(message_type)
      {
        return "/img/message_type/" + message_type + ".png";
      },
      getDrafts() {
        let self = this;
        this.loading = true;
        const minDate = new Date(0).toISOString();
        const maxDate = new Date('2029-12-31T23:59:59.999Z').toISOString();
  
        this.$http.get(`${this.user.hostname}/meetings/drafts`, {
            params: {
                StartDate: minDate,
                EndDate: maxDate,
                isDraft: true,
                search: this.searchText,
            }
        })
        .then((response) => {
          self.redrawIndex++;
          self.loading = false;
          self.$emit("changeUpdateMessageUuid", "");
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
      },
    },
    computed: {
      fields() {
        return [
          {
            key: "id",
            class: "hidden-not-mobile",
            thClass: "hidden",
          },
          {
            key: "location",
            label: "",
            class: "text-break w-icon clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "subject",
            label: this.$t("SUBJECT"),
            class: "text-break clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "startTime",
            label: this.$t("PICK_DATE_TIMES.START_TIME"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "endTime",
            label: this.$t("PICK_DATE_TIMES.END_TIME"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
        ];
      },
    },
    watch: {
      searchText() {
        if (this.searchText.length == 0 || this.searchText.length >= 2) {
          this.getDrafts();
        }
      },
      refresh(){
        this.getDrafts();
      },
      limit() {
        this.getDrafts();
      },
    },
    mounted() {
      this.getDrafts();
    },
  };
  </script>
  
  <style></style>
  